<script>
    import General from '@/services/General';
    import Swal from "sweetalert2";
    import vue2Dropzone from "vue2-dropzone";
    import "vue2-dropzone/dist/vue2Dropzone.min.css";

    export default {
        components:{  vueDropzone: vue2Dropzone   },
        props: ['path'],
        data() {
            return {
                dir_name:'',
                showModal: false,
                tryingToEdit: false,
                submitted: false,
                showLoader:false,
                file_small:'',

                dropzoneOptions_primary: {
                    url: '/',
                    autoProcessQueue: false,
                    addRemoveLinks: true,
                    thumbnailWidth: 150,
                    thumbnailMethod: 'contain',
                    maxFilesize: 25,
                  //  acceptedFiles:'image/*',
                    maxFiles: 1,
                },
            };
        },

        created() {

        },
        methods: {
            async uploadFile(){
                this.tryingToEdit = true;
                this.submitted = true;
                 //console.log(this.path + '/' + this.dir_name);
                try {
                        let formData = new FormData();
                        if(this.file_small){
                            formData.append('file', this.file_small)
                        }
                        formData.append('path', this.path);

                    await General.uploadFile(formData).then((response) => {
                        this.successmsg(response);
                        this.closeModal();
                    }).catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    }).finally(() => {
                        this.refreshData()
                        this.tryingToEdit = false;
                    })
                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg(this.error)
                }
            },


            refreshData() {
                this.$emit('onRefresh') //event from parent
            },

            closeModal() {
                this.showModal = false;
            },

            successmsg() {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "File uploaded!",
                    showConfirmButton: false,
                    timer: 2000,
                });
            },

            failedmsg(msg) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: msg,
                    showConfirmButton: false,
                    timer: 5000,
                });
            },

             selectFileSmall(files){
                 setTimeout(() => {
                        let queuedFiles = this.$refs.myVueDropzone_primary.getQueuedFiles();

                        if (queuedFiles.length > 1) {
                            this.failedmsg('You can only have one file')

                            files.forEach((value) => {
                                this.$refs.myVueDropzone_primary.removeFile(value)
                            });
                        }

                        this.file_small = queuedFiles[0];
                 }, 250);
            },
        }
    };
</script>

<template>
    <b-modal  v-model="showModal" id="upload_file" title="Upload File" title-class="font-18" size="l" centered>
        <template v-if="!showLoader">
            <div class="row mb-3" >
                <div class="col-sm-12">

                        <b-form-group title="Images" title-item-class="mb-2">
                            <b-card-text class="text-center">

                                <div class="row mb-12">
                                    <div class="col-12">
                                        <h5 class="text-center">File</h5>
                                        <vue-dropzone
                                                id="dropzone_primary"
                                                ref="myVueDropzone_primary"
                                                :use-custom-slot="true"
                                                :options="dropzoneOptions_primary"
                                                @vdropzone-files-added="selectFileSmall"
                                                no-progress-bar
                                        >
                                            <div class="dropzone-custom-content">
                                                <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                                <h4>Drop file here or click to upload.</h4>
                                            </div>
                                        </vue-dropzone>

                                    </div>

                                </div>

                            </b-card-text>
                        </b-form-group>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="card-body d-flex align-items-center justify-content-center" v-if="showLoader">
                <b-spinner large></b-spinner>
            </div>
        </template>
        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="uploadFile" :disabled="tryingToEdit || showLoader">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Upload</b-button>
        </template>
    </b-modal>
</template>