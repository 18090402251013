<script>
import General from '@/services/General';
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";

export default {
    components: {},
    props: ['path'],
    data() {
        return {
            file: '',
            mime: '',
            size: '',
            time: '',
            showModal: false,
            tryingToEdit: false,
            submitted: false,
            showLoader: false,
            fileContent: '',
            file_type: '',

        };
    },

    validations: {
        dir_name: {
            required
        },

    },
    created() {

    },
    methods: {

        async getFile() {
            this.showLoader = true;
            try {

                var filters = '&path=' + this.path;
                await General.getFile(filters).then((response) => {
                    var file_data = response.data.data;
                    this.file = file_data.file;
                    this.mime = file_data.mime;
                    this.size = file_data.size;
                    this.time = file_data.time;
                    this.file_type = this.mime.substring(0, this.mime.lastIndexOf("/"));
                    this.fileContent = this.file;
                    this.showLoader = false;

                }).catch(error => {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg(this.error)
                }).finally(() => {
                    this.refreshData()
                    this.tryingToEdit = false;
                })
            } catch (error) {
                this.error = error.response.data.error ? error.response.data.error : "";
                this.failedmsg(this.error)
            }
        },
        copyFileContent() {
            try {
                navigator.clipboard.writeText(this.fileContent);

                this.successmsg('Content copied to clipboard!');
            } catch (error) {
                this.failedmsg('Failed to copy content');
            }
        },





        refreshData() {
            this.$emit('onRefresh') //event from parent
        },

        closeModal() {
            this.showModal = false;
        },
        copyModal() {

            const copyIcon = '<i class="fas fa-copy"></i>';

            Swal.fire({
                html: `${copyIcon} `,
            });
        },

        successmsg(message) {
            Swal.fire({
                position: "center",
                icon: "success",
                title: message,
                showConfirmButton: false,
                timer: 2000,
            });
        },

        failedmsg(msg) {
            Swal.fire({
                position: "center",
                icon: "error",
                title: msg,
                showConfirmButton: false,
                timer: 5000,
            });
        },

    }
};
</script>


<template>
    <b-modal v-model="showModal" @shown="getFile" id="view_file" title="File Details" title-class="font-18" size="l"
        centered>
        <template v-if="!showLoader">
            <div class="text-muted">

                <div v-if="file_type == 'image'" align=center>
                    <img :src="file" alt="" width="150" class="img-thumbnail">
                </div>

                <p class="font-size-16 mb-2">
                    <span class="text-strong">File:</span>
                    <button @click="copyFileContent">
                        <i class="fas fa-copy" style="margin-left: 5px;"></i>
                    </button>
                    <a :href="file" target="_blank" class="file-path-wrap">{{ file }}</a>
                </p>

                <p class="font-size-16 mb-2"><span class="text-strong">Date:</span> {{ time }}</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Size:</span> {{ size }}MB</p>
                <p class="font-size-16 mb-2"><span class="text-strong">Mime:</span> {{ mime }}</p>


            </div>

            <!--
                <div class="row" >
                    <div class="card-body d-flex align-items-center justify-content-center">
                       <iframe  :src="file"  ></iframe>
                    </div>

            </div>  -->

        </template>
        <template v-else>
            <div class="card-body d-flex align-items-center justify-content-center" v-if="showLoader">
                <b-spinner large></b-spinner>
            </div>
        </template>
        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>

        </template>
    </b-modal>
</template>
<style scoped>
.file-path-wrap {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
}
</style>