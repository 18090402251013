<script>
    import General from '@/services/General';
    import Swal from "sweetalert2";
    import { required } from "vuelidate/lib/validators";
    import validationMessages from '@/components/validations';


    export default {
        components:{   validationMessages  },
        props: ['path'],
        data() {
            return {
                dir_name:'',
                showModal: false,
                tryingToEdit: false,
                submitted: false,
                showLoader:false,

            };
        },

         validations: {
            dir_name: {
                required
            },

        },
        created() {

        },
        methods: {

            async createDirectory(){

                this.tryingToEdit = true;
                this.submitted = true;
                 console.log(this.path + '/' + this.dir_name);
                try {

                    await General.createDirectory({


                        path: this.path + '/' + this.dir_name,

                    }).then((response) => {
                        this.successmsg(response);
                        this.closeModal();
                    }).catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error)
                    }).finally(() => {
                        this.refreshData()
                        this.tryingToEdit = false;
                    })
                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg(this.error)
                }
            },



            refreshData() {
                this.$emit('onRefresh') //event from parent
            },

            closeModal() {
                this.showModal = false;
            },

            successmsg() {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Folder created!",
                    showConfirmButton: false,
                    timer: 2000,
                });
            },

            failedmsg(msg) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: msg,
                    showConfirmButton: false,
                    timer: 5000,
                });
            },


        }
    };
</script>

<template>
    <b-modal  v-model="showModal" id="create_directory" title="Create Directory" title-class="font-18" size="l" centered>
        <template v-if="!showLoader">
            <div class="row mb-3" >
                <div class="col-sm-6">
                    <b-form-group label="Directory Name" label-for="formrow-directory_name-input" class="mb-3">
                        <b-form-input
                                v-model.trim="dir_name"
                                type="text"
                                id="dir_name"
                                aria-describedby="firstname-feedback"
                                :class="{
                                  'is-invalid': submitted && $v.dir_name.$error,
                                }"
                        >
                        </b-form-input>
                        <validationMessages v-if="submitted" :fieldName="'Directory Name'" :validationName="$v.dir_name"></validationMessages>
                    </b-form-group>


                </div>
            </div>
        </template>
        <template v-else>
            <div class="card-body d-flex align-items-center justify-content-center" v-if="showLoader">
                <b-spinner large></b-spinner>
            </div>
        </template>
        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="createDirectory" :disabled="tryingToEdit || showLoader">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Create</b-button>
        </template>
    </b-modal>
</template>