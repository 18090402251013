<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import General from '@/services/General';
import ModalViewFile from "@/components/modals/media/modalViewFile";
import ModalCreateDirectory from "@/components/modals/media/modalCreateDirectory";
import ModalUploadFile from "@/components/modals/media/modalUploadFile";
import Swal from "sweetalert2";

export default {
  components: { Layout, PageHeader, ModalCreateDirectory, ModalUploadFile, ModalViewFile },
  page: {
    title: "Media Management",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      tenant: process.env.VUE_APP_TENANT,
      dirContent: [],

      title: "Media Management",
      items: [
        {
          text: "Media",
        },
        {
          text: "Media Management",
          active: true,
        },
      ],
      filterInput: {
      },

      defaultPath: '',
      filePath: '',
      backPath: false,
      delete_folder: false,
      breadcrumbModified: '',
      breadcrumbArray: [],
      breadcrumbItems: [],
      showLoader: false,
      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "filename",
      sortDesc: false,
      fields: [
        {
          key: "filename",
          label: "Name",
        },
        {
          key: "date",
          label: "Date",
        },

        {
          key: "size_mb",
          label: "Size (MB)",
        },
        "action",
      ],



    };
  },

  async created() {
    this.getDirectoryContent(this.defaultPath);

  },


  methods: {

    async getDirectoryContent(path) {
      this.showLoader = true;
      this.backPath = path.substring(0, path.lastIndexOf("/"));
      this.isBusy = true;
      this.delete_folder = false;

      try {
        this.filterData = true;
        //this.toggleBusy();
        var filters = '';
        if (path != '') {
          filters = '&path=' + path;
        }

        const response = await General.getDirContent(filters);
        this.dirContent = response.data.data;
        if (response.data.data.length == 0) {
          this.delete_folder = true;
        }
        this.tableData = response.data.data;
        this.totalRows = this.tableData.length
        this.isBusy = false;

        this.defaultPath = path;
        this.generateBreadcrumb();
        this.showLoader = false;

      } catch (error) {
        //this.toggleBusy();
        this.isBusy = false;
        this.error = error.response.data.error ? error.response.data.error : "";

      }
    },

    generateBreadcrumb() {
      this.breadcrumbItems = [];
      this.breadcrumbModified = '';
      var breadcrumbs = this.defaultPath.split('/');
      this.breadcrumbArray = breadcrumbs;
      var generated_breadcrumb = '';

      this.breadcrumbModified += '<a href="javascript:void(0);" @click="getDirectoryContent()"> root </i></a>' + ' / ';

      var items = [];
      var item = [];
      item.name = 'root';
      item.link = '';
      //this.breadcrumbItems.push(item);


      for (var i = 0, l = breadcrumbs.length; i < l; i++) {
        generated_breadcrumb += breadcrumbs[i] + '/';
        item.name = breadcrumbs[i];
        item.link = generated_breadcrumb;

        this.breadcrumbItems.push({
          name: breadcrumbs[i],
          link: generated_breadcrumb
        });

        if (i != l - 1) {
          var test = "'" + generated_breadcrumb + "'";

          this.breadcrumbModified += '<a href="javascript:void(0);" @click="getDirectoryContent(' + test + ')"> ' + breadcrumbs[i] + '</i></a>' + ' / ';
        }
        else if (i == l - 1) {
          this.breadcrumbModified += breadcrumbs[i];
        }
      }


    },


    callModalCreateDirectory() {
      this.$bvModal.show("create_directory");
    },

    callModalUploadFile() {
      this.$bvModal.show("upload_file");
    },
    callModalViewFile(file_path) {
      this.filePath = file_path;
      this.$bvModal.show("view_file");
    },

    /**
        * Search the table data with search input
        */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    deleteFolder() {
      Swal.fire({
        title: "Are you sure?",
        text: "Delete folder!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          General.deleteDirectory('&path=' + this.defaultPath)
            .then((response) => {
              const res = response.data.data ? response.data.data : false;
              const error = response.data.error ? response.data.error : '';
              if (res && error == '') {
                this.getDirectoryContent(this.backPath);
                this.successmsg("Folder Successfully Deleted")
              } else {
                this.failedmsg("Fail!")
              }
            })
            .catch(error => {
              this.failedmsg('Fail!', error)
            });
        }
      });
    },

    deleteFile(file_path) {
      Swal.fire({
        title: "Are you sure?",
        text: "Delete file!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          General.deleteFile('&path=' + file_path)
            .then((response) => {
              const res = response.data.data ? response.data.data : false;
              const error = response.data.error ? response.data.error : '';
              if (res && error == '') {
                this.getDirectoryContent(this.defaultPath);
                this.successmsg("File Successfully Deleted")
              } else {
                this.failedmsg("Fail!")
              }
            })
            .catch(error => {
              this.failedmsg('Fail!', error)
            });
        }
      });
    },


  },
  middleware: "authentication",
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <template v-if="!showLoader">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">

              <div class="row" v-show="defaultPath !== backPath">
                <div class="row">
                  <div class="col-sm-12">
                    <a href="javascript:void(0);" @click="getDirectoryContent('')" style="display: inline-block;">
                      root</a> &nbsp;/
                    <span v-for=" (breadcrumb, index) in breadcrumbItems" :key="index" style="display: inline-block;">
                      <span v-if="index == breadcrumbItems.length - 1" style="display: inline-block;">{{ breadcrumb.name
                      }}
                      </span>
                      <a v-else href="javascript:void(0);" @click="getDirectoryContent(breadcrumb.link)"
                        style="display: inline-block;"> {{ breadcrumb.name }}&nbsp;/&nbsp; </a>
                    </span><br>
                  </div>

                </div>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light"
                      @click="callModalUploadFile">
                      <i class="mdi mdi-folder-upload me-2"></i>
                      Upload File
                    </a>
                    <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light"
                      style="margin-left:5px;" @click="callModalCreateDirectory">
                      <i class="mdi mdi-folder me-2"></i>
                      Create Folder
                    </a>
                    <a v-if="delete_folder" href="javascript:void(0);" class="btn btn-success waves-effect waves-light"
                      style="margin-left:5px;" @click="deleteFolder">
                      <i class="mdi mdi-delete-empty me-2"></i>
                      Delete Folder
                    </a>
                  </div>
                </div>
              </div>

              <div class=" table-centered datatable dt-responsive nowrap table-card-list dataTable no-footer dtr-inline">
                <div class="row">
                  <div class="col-sm-12 col-md-6" style="margin-top: 5px;">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center fw-normal">
                        Show
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions" style="margin-left: 5px; margin-right: 5px;"></b-form-select>entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                      <label class="d-inline-flex align-items-center fw-normal">
                        Search:
                        <b-form-input v-model="filter" type="search" placeholder="Search..."
                          class="form-control form-control-sm ms-2"></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <!-- Table -->

                <b-table table-class="table-responsive mb-0" :busy="isBusy" :items="tableData" :fields="fields"
                  responsive="sm" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn" sort-icon-left
                  @filtered="onFiltered" show-empty empty-text="No Data Found">
                  <template v-slot:cell(filename)="data">

                    <div v-if="data.item.type == 'file'">
                      <a href="javascript:void(0);" @click="callModalViewFile(data.item.path)">
                        <!--   <i class="fa fa-file px-2 text-primary"></i>{{data.item.filename + '.' + data.item.extension }} -->
                        <i class="fa fa-file px-2 text-primary"></i>{{ data.item.basename }}
                      </a>
                    </div>

                    <div v-if="data.item.type == 'dir'">
                      <a href="javascript:void(0);" class="waves-effect waves-light"
                        @click="getDirectoryContent(data.item.path)"> <i class="fa fa-folder px-2 text-warning"></i>
                        <!--{{data.item.filename}} -->
                        {{ data.item.basename }}
                      </a>
                    </div>
                  </template>

                  <template v-slot:cell(action)="data">
                    <ul class="list-inline mb-0">
                      <li class="list-inline-item">

                        <a v-if="data.item.type == 'file'" href="javascript:void(0);" class="px-2 text-danger"
                          title="Delete File" @click="deleteFile(data.item.path)">
                          <i class="uil uil-trash-alt font-size-18"></i>
                        </a>
                      </li>
                    </ul>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-end">
                    <ul class="pagination pagination-rounded">
                      <!-- pagination -->
                      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="card-body d-flex align-items-center justify-content-center" v-if="showLoader">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row  align-items-center justify-content-center">
                  <b-spinner large></b-spinner>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <!-- MODALS -->
    <ModalCreateDirectory :path="defaultPath" @onRefresh="getDirectoryContent(defaultPath);"></ModalCreateDirectory>
    <ModalUploadFile :path="defaultPath" @onRefresh="getDirectoryContent(defaultPath);"></ModalUploadFile>
    <ModalViewFile :path="filePath"></ModalViewFile>
    <!-- /MODALS -->

  </Layout>
</template>
